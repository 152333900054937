import React, { useEffect } from "react";
import "./investorContacts.scss";
import {
  investCall,
  investEmail,
  investLocation,
  InvestorBgImg,
  InvestorFgImg,
  investPrint,
  investWeb,
  textilesProspectus,
} from "../../images";
import { Link } from "react-router-dom";
import Dash from "../../components/Dash/Dash";
import InvestTab from "../../components/InvestTab/InvestTab";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";
import Stock from "../../components/StockIndicator/Stock";

const tabTopData = [
  {
    id: 1,
    title: "Corporate Governance",
    link: "/investor-relations/corporate-governance#corporate",
  },
  {
    id: 2,
    title: "Financial Performance & Presentation",
    link: "/investor-relations/financial-performance#financial",
  },
  // {
  //   id: 3,
  //   title: "Annual Reports",
  //   link: "/investor-relations/annual-reports#annual",
  // },
  {
    id: 4,
    title: "Announcements",
    link: "/investor-relations/announcements#announcement",
  },
  {
    id: 5,
    title: "Shareholding Pattern",
    link: "/investor-relations/shareholding-pattern#shareholding",
  },
  {
    id: 6,
    title: "Intimation of Investor Meet",
    link: "/investor-relations/investor-meet-presentation#intimation",
  },
  {
    id: 7,
    title: "Investor Contacts",
    link: "/investor-relations/investor-contacts#investor",
  },
];

const tabBottomData = [
  {
    id: 8,
    title:
      "Disclosures under Regulation 46 and 62 of SEBI (LODR) Regulations, 2015",
    link: "/investor-relations/disclosures-under-regulation-46-and-62-of-sebi-lodr-regulations-2015#disclosure",
  },
];

const InvestorContacts = () => {
  const { width } = useWindowDimensions();
  const location = useLocation();
  const history = useHistory();

  const tabListBottom = tabBottomData.map((tab, i) => (
    <NavLink
      className="tab_name"
      activeClassName="active"
      exact
      to={tab.link}
      key={`tab-${i}`}
    >
      {tab.title}
    </NavLink>
  ));

  const tabListTop = tabTopData.map((tab, i) => (
    <NavLink
      className="tab_name"
      activeClassName="active"
      exact
      to={tab.link}
      key={`tab-${i}`}
    >
      {tab.title}
    </NavLink>
  ));

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const targetId = hash.substring(1);
      const targetSection = document.getElementById(targetId);
      if (targetSection) {
        setTimeout(() => {
          const offset = 100; // Gap from viewport top
          const targetPosition =
            targetSection.getBoundingClientRect().top + window.scrollY - offset;
          window.scrollTo({ top: targetPosition, behavior: "smooth" });
          // targetSection.scrollIntoView({ behavior: "smooth" });
        }, 0);
        // history.replace("/investor-relations/investor-meet-presentation");
        history.replace(location.pathname);
      }
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
       <MetaDecorator
        canonicalLink={window.location.href}
        metaTitle="Investor Contacts | Sanathan Investor Relations"
        metaDesc="Access Sanathan’s Investor Contacts for inquiries and assistance. Connect with our dedicated team for all investor-related queries. Visit us for more details!"
      />

      <section className="invest_contact_sec1 investor_relations">
        <div className="wrapper">
          <div className="breadcrumb_styles">
            <Link to="/" className="breadcrumb_link">
              Home
            </Link>{" "}
            {">"} Investor Relations
          </div>
          <div className="left_section">
            <h1 className="hollow_title">Investor Relations</h1>
            <div className="dash_wrapper">
              <Dash />
            </div>
            {/* <h3></h3> */}
            {/* <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet.
            </p> */}
          </div>
          <div className="right_section">
            <figure className="bg_img">
              <img src={InvestorBgImg} alt="sanathan img" />
            </figure>
            <figure className="fg_img">
              <img src={InvestorFgImg} alt="sanathan img" />
            </figure>
            <div className="box_stock_hold">
              {width >= 769 && <Stock />}
            </div>
          </div>
          {/* mobile section for image*/}
          <div className="mobile_section">
            <figure className="mob_bg_img">
              <img
                className="mob_img1"
                src={InvestorBgImg}
                alt="sanathan img"
              />
            </figure>
            <figure className="mob_fg_img">
              <img src={InvestorFgImg} alt="sanathan img" />
            </figure>
          </div>
        </div>
      </section>
      <section className="stockWrapper stck_ivstor_rel">
        <div className="wrapper">{width <= 768 && <Stock />}</div>
      </section>
      <section className="invest_contact_sec2" id="investor">
        <div className="wrapper">
          <div className="row invest_row">
            <div className="col-md-4 invest_col">
              {width > 767 ? (
                <InvestTab />
              ) : (
                <div className="invest_tab_wrapper">{tabListTop}</div>
              )}
            </div>
            <div className="col-md-8 invest_col">
              <h2 className="heading">Investor Contacts</h2>

              <div className="contact_box">
                <h2 className="title">
                  1. GRIEVANCE REDRESSAL CONTACTS (RTA){" "}
                </h2>
                <p className="desc">
                  For transfer, dematerialisation of shares, payment of dividend
                  on shares and any other query relating to the equity shares
                  held in demat form contact the following:
                </p>
                <div className="contact_data">
                  <h2 className="title">REGISTRAR AND SHARE TRANSFER AGENT</h2>
                  <h2 className="title">KFin Technologies Limited </h2>
                  <h2 className="title">
                    (formerly known as KFin Technologies Private Limited){" "}
                  </h2>
                  <div className="data_wrapper loc_wrapper">
                    <img
                      src={investLocation}
                      alt="location"
                      className="share_icon loc_icon"
                    />
                    <a
                      href="https://maps.app.goo.gl/Tuadnz9GWmxsxTGW9"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {/* <h2 className="title">
                    Ms. UNNATI KANANI (COMPANY SECRETARY / NODAL OFFICER)
                  </h2> */}
                      <p className="desc map_desc">
                        Selenium, Tower B, Plot No. 31 and 32, Gachibowli,
                        Financial District, Nanakramguda, Serilingampally
                        Hyderabad - 500 032, Telangana, India
                      </p>
                    </a>
                  </div>
                  <div className="data_wrapper">
                    <img
                      src={investCall}
                      alt="location"
                      className="share_icon"
                    />
                    <a href="tel:+914067162222">
                      <p className="desc">+ 91 40 6716 2222 </p>
                    </a>
                  </div>
                  {/* <div className="data_wrapper">
                    <img
                      src={investPrint}
                      alt="location"
                      className="share_icon"
                    />
                    <a href="tel:+912249186000">
                      <p className="desc">+91 22 4918 6000</p>
                    </a>
                  </div> */}
                  <div className="data_wrapper">
                    <img
                      src={investEmail}
                      alt="location"
                      className="share_icon"
                    />
                    <a href="mailto:einward.ris@kfintech.com">
                      <p className="desc">einward.ris@kfintech.com </p>
                    </a>
                  </div>
                  <div className="data_wrapper">
                    <img
                      src={investWeb}
                      alt="location"
                      className="share_icon"
                    />
                    {/* <Link to="/"> */}
                      <p className="desc">www.kfintech.com </p>
                    {/* </Link> */}
                  </div>

                  <p className="desc note_desc">
                    *Please cite the DP ID and Client ID in all your
                    correspondence along with the name of the company.
                  </p>
                </div>
              </div>

              <div className="contact_box contact_box_1">
                <h2 className="title title_1">
                  2. INVESTOR RELATIONS TEAM OF COMPANY:
                </h2>
                <div className="contact_data contact_data_1">
                  <h2 className="title">Mr. Jude Patrick Dsouza</h2>
                  <h2 className="title">
                    Company Secretary and Compliance Officer{" "}
                  </h2>
                  {/* <div className="data_wrapper loc_wrapper">
                    <img
                      src={investLocation}
                      alt="location"
                      className="share_icon loc_icon"
                    />
                    <a
                      href="https://maps.app.goo.gl/mx96cbb3AkjY3uB57"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p className="desc map_desc">
                        D15, trade world, kamala mill compound, senapati bapat
                        Marg, lower Parel West Mumbai, Maharashtra 400013.
                      </p>
                    </a>
                  </div> */}
                  <div className="data_wrapper">
                    <img
                      src={investCall}
                      alt="location"
                      className="share_icon"
                    />
                    {/* <a href="tel:+912249186000"> */}
                      <p className="desc">+ 91 22 6634 3312/3/4/5/6 </p>
                    {/* </a> */}
                  </div>
                  {/* <div className="data_wrapper">
                    <img
                      src={investPrint}
                      alt="location"
                      className="share_icon"
                    />
                    <a href="tel:+912249186000">
                      <p className="desc">+91 22 4918 6000</p>
                    </a>
                  </div> */}
                  <div className="data_wrapper">
                    <img
                      src={investEmail}
                      alt="location"
                      className="share_icon"
                    />
                    <a href="mailto:investors@sanathan.com">
                      <p className="desc">investors@sanathan.com </p>
                    </a>
                  </div>
                  {/* <div className="data_wrapper">
                    <img
                      src={investWeb}
                      alt="location"
                      className="share_icon"
                    />
                    <Link to="/">
                      <p className="desc">https://www.sanathan.com/</p>
                    </Link>
                  </div> */}
                </div>
              </div>

              <div className="contact_box contact_box_1">
                <h2 className="title title_1">SANATHAN TEXTILES LIMITED</h2>
                <div className="contact_data contact_data_1">
                  <p className="desc map_desc"><b>CIN:</b> U17299DN2005PLC005690</p>
                  <p className="desc map_desc"><b>Reg. Off:</b> SRV NO. 187/4/1/2, Near Surangi </p>
                  <p className="desc map_desc">Bridge, Surangi, Dadra & Nagar Haveli, </p>
                  <p className="desc map_desc">Silvassa - 396230, India </p>
                  <p className="desc map_desc"><b>Corporate Off:</b>  D-15, Trade World Building Kamala Mills </p>
                  <p className="desc map_desc">Compound, Senapati Bapat Marg Lower Parel, </p>
                  <p className="desc map_desc">Mumbai – 400013, Maharashtra, India </p>
                  {/* <div className="data_wrapper loc_wrapper">
                    <img
                      src={investLocation}
                      alt="location"
                      className="share_icon loc_icon"
                    />
                    <a
                      href="https://maps.app.goo.gl/mx96cbb3AkjY3uB57"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p className="desc map_desc">
                        D15, trade world, kamala mill compound, senapati bapat
                        Marg, lower Parel West Mumbai, Maharashtra 400013.
                      </p>
                    </a>
                  </div>
                  <div className="data_wrapper">
                    <img
                      src={investCall}
                      alt="location"
                      className="share_icon"
                    />
                    <a href="tel:+912249186000">
                      <p className="desc">+91 22 4918 6000</p>
                    </a>
                  </div>
                  <div className="data_wrapper">
                    <img
                      src={investPrint}
                      alt="location"
                      className="share_icon"
                    />
                    <a href="tel:+912249186000">
                      <p className="desc">+91 22 4918 6000</p>
                    </a>
                  </div>
                  <div className="data_wrapper">
                    <img
                      src={investEmail}
                      alt="location"
                      className="share_icon"
                    />
                    <a href="mailto:sales@sanathan.com">
                      <p className="desc">sales@sanathan.com</p>
                    </a>
                  </div>
                  <div className="data_wrapper">
                    <img
                      src={investWeb}
                      alt="location"
                      className="share_icon"
                    />
                    <Link to="/">
                      <p className="desc">https://www.sanathan.com/</p>
                    </Link>
                  </div> */}
                </div>
              </div>

              {width <= 767 && (
                <div className="invest_tab_wrapper invest_tab_wrapper_2">
                  {tabListBottom}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InvestorContacts;
