import React, { useEffect, useState } from "react";
import "./financialPerformance.scss";
import {
  InvestorBgImg,
  InvestorFgImg,
  textilesProspectus,
  file1,
  file4,
  file7,
  file28,
  file29,
  file2,
  file5,
  file8,
  file3,
  file6,
  file9,
  finanQu2File1,
  finanQu2File2,
  finanQu2File3,
  finanQu2File4,
  earningsCall,
  finanQu2File6,
  finanQu3board,
  finanQu3InvestorMeet,
  finanQu3OutCome,
  finanQu3Press,
  finanQu3Insider,
  finanQu3Statement,
  finanQu3EarningsCall,
  finanQu3EarningsTrans,
  finanQu3StlInvest,
  finanQu3StlSigned,
  finanQu4StlMagazineMar25,
} from "../../images";
import { Link } from "react-router-dom";
import Dash from "../../components/Dash/Dash";
import FinanceAcc from "../../components/FinanceAcc/FinanceAcc";
import InvestTab from "../../components/InvestTab/InvestTab";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import InvestAcc from "../../components/InvestAcc/InvestAcc";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";
import Stock from "../../components/StockIndicator/Stock";

const tabTopData = [
  {
    id: 1,
    title: "Corporate Governance",
    link: "/investor-relations/corporate-governance#corporate",
  },
  {
    id: 2,
    title: "Financial Performance & Presentation",
    link: "/investor-relations/financial-performance#financial",
  },
];

const tabBottomData = [
  // {
  //   id: 3,
  //   title: "Annual Reports",
  //   link: "/investor-relations/annual-reports#annual",
  // },
  {
    id: 4,
    title: "Announcements",
    link: "/investor-relations/announcements#announcement",
  },
  {
    id: 5,
    title: "Shareholding Pattern",
    link: "/investor-relations/shareholding-pattern#shareholding",
  },
  {
    id: 6,
    title: "Intimation of Investor Meet",
    link: "/investor-relations/investor-meet-presentation#intimation",
  },
  {
    id: 7,
    title: "Investor Contacts",
    link: "/investor-relations/investor-contacts#investor",
  },
  {
    id: 8,
    title:
      "Disclosures under Regulation 46 and 62 of SEBI (LODR) Regulations, 2015",
    link: "/investor-relations/disclosures-under-regulation-46-and-62-of-sebi-lodr-regulations-2015#disclosure",
  },
];

const financialPerformanceAnual = [
  {
    id: 1,
    mainTitle: "2023-2024",
    data: [
      {
        title: "STL Financials 2024",
        link: file1,
      },
      {
        title: "SPPL Financials 2024",
        link: file4,
      },
      {
        title: "UTPL Financials 2024",
        link: file7,
      },
      {
        title: "STL Annual Return 2024",
        link: file28,
      },
      {
        title: "SPPL Annual Return 2024",
        link: file29,
      },
    ],
  },
  {
    id: 2,
    mainTitle: "2022-2023",
    data: [
      {
        title: "STL Financials 2023",
        link: file2,
      },
      {
        title: "SPPL Financials 2023",
        link: file5,
      },
      {
        title: "UTPL Financials 2023",
        link: file8,
      },
    ],
  },
  {
    id: 3,
    mainTitle: "2021-2022",
    data: [
      {
        title: "STL Financials 2022",
        link: file3,
      },
      {
        title: "SPPL Financials 2022",
        link: file6,
      },
      {
        title: "UTPL Financials 2022",
        link: file9,
      },
    ],
  },
];

const financialPerformance2425 = [
  {
    id: 1,
    mainTitle: "Quarter 4",
    data: [
      {
        title: "Intimation of Trading Window closure",
        link: finanQu4StlMagazineMar25,
      },
    ],
  },
  {
    id: 2,
    mainTitle: "Quarter 3",
    data: [
      {
        title: "Investor Presentation",
        link: finanQu3StlSigned,
      },
      {
        title: "Investors Meet Intimation",
        link: finanQu3StlInvest,
      },
      {
        title: "Earnings Call Transcript",
        link: finanQu3EarningsTrans,
      },
      {
        type: "audio",
        title: "Earnings Call Recording",
        link: finanQu3EarningsCall,
      },
      {
        title: "Intimation of Board Meeting",
        link: finanQu3board,
      },
      {
        title: "Intimation of Earnings Call",
        link: finanQu3InvestorMeet,
      },
      {
        title: "Outcome of Board Meeting",
        link: finanQu3OutCome,
      },
      {
        title: "Press Release",
        link: finanQu3Press,
      },
      {
        title: "Statement of deviation of Variation",
        link: finanQu3Statement,
      },
    ],
  },
  {
    id: 3,
    mainTitle: "Quarter 2",
    data: [
      {
        title: "Intimation of Board Meeting",
        link: finanQu2File1,
      },
      {
        title: "Intimation of Earnings Call",
        link: finanQu2File2,
      },
      {
        title: "Outcome of Board Meeting",
        link: finanQu2File3,
      },
      {
        title: "Press Release",
        link: finanQu2File4,
      },
      {
        type: "audio",
        title: "Earnings Call Recording",
        link: earningsCall,
      },
      {
        title: "Earnings Call Transcript",
        link: finanQu2File6,
      },
    ],
  },
];

const financialPerformance2324 = [
  {
    id: 1,
    mainTitle: "Quarter 4",
    data: [
      {
        title: "STL Financials June 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2023",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2022",
        link: textilesProspectus,
      },
    ],
  },
  {
    id: 2,
    mainTitle: "Quarter 3",
    data: [
      {
        title: "STL Financials 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2023",
        link: textilesProspectus,
      },
    ],
  },
  {
    id: 2,
    mainTitle: "Quarter 2",
    data: [
      {
        title: "STL Financials 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2023",
        link: textilesProspectus,
      },
    ],
  },
  {
    id: 2,
    mainTitle: "Quarter 1",
    data: [
      {
        title: "STL Financials 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2023",
        link: textilesProspectus,
      },
    ],
  },
];

const financialPerformance2223 = [
  {
    id: 1,
    mainTitle: "Quarter 4",
    data: [
      {
        title: "STL Financials June 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2023",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2022",
        link: textilesProspectus,
      },
    ],
  },
  {
    id: 2,
    mainTitle: "Quarter 3",
    data: [
      {
        title: "STL Financials 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2023",
        link: textilesProspectus,
      },
    ],
  },
  {
    id: 2,
    mainTitle: "Quarter 2",
    data: [
      {
        title: "STL Financials 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2023",
        link: textilesProspectus,
      },
    ],
  },
  {
    id: 2,
    mainTitle: "Quarter 1",
    data: [
      {
        title: "STL Financials 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2023",
        link: textilesProspectus,
      },
    ],
  },
];

const financialPerformance2122 = [
  {
    id: 1,
    mainTitle: "Quarter 4",
    data: [
      {
        title: "STL Financials June 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2023",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2022",
        link: textilesProspectus,
      },
    ],
  },
  {
    id: 2,
    mainTitle: "Quarter 3",
    data: [
      {
        title: "STL Financials 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2023",
        link: textilesProspectus,
      },
    ],
  },
  {
    id: 2,
    mainTitle: "Quarter 2",
    data: [
      {
        title: "STL Financials 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2023",
        link: textilesProspectus,
      },
    ],
  },
  {
    id: 2,
    mainTitle: "Quarter 1",
    data: [
      {
        title: "STL Financials 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2023",
        link: textilesProspectus,
      },
    ],
  },
];

const financialPerformanceTab2425 = [
  {
    id: 1,
    mainTitle: "Quarter 4",
    data: [
      {
        title: "STL Financials June 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2023",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2022",
        link: textilesProspectus,
      },
    ],
  },
  {
    id: 2,
    mainTitle: "Quarter 3",
    data: [
      {
        title: "STL Financials 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2023",
        link: textilesProspectus,
      },
    ],
  },
  {
    id: 2,
    mainTitle: "Quarter 2",
    data: [
      {
        title: "STL Financials 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2023",
        link: textilesProspectus,
      },
    ],
  },
  {
    id: 2,
    mainTitle: "Quarter 1",
    data: [
      {
        title: "STL Financials 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2023",
        link: textilesProspectus,
      },
    ],
  },
];

const financialPerformanceTab2324 = [
  {
    id: 1,
    mainTitle: "Quarter 4",
    data: [
      {
        title: "STL Financials June 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2023",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2022",
        link: textilesProspectus,
      },
    ],
  },
  {
    id: 2,
    mainTitle: "Quarter 3",
    data: [
      {
        title: "STL Financials 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2023",
        link: textilesProspectus,
      },
    ],
  },
  {
    id: 2,
    mainTitle: "Quarter 2",
    data: [
      {
        title: "STL Financials 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2023",
        link: textilesProspectus,
      },
    ],
  },
  {
    id: 2,
    mainTitle: "Quarter 1",
    data: [
      {
        title: "STL Financials 2024",
        link: textilesProspectus,
      },
      {
        title: "STL Financials 2023",
        link: textilesProspectus,
      },
    ],
  },
];

const FinancialPerformance = () => {
  const [isActivTab, setIsActivTab] = useState("quarterly");
  const [activeKey, setActiveKey] = useState("left-0");
  const [activeKeyTab2, setActiveKeyTab2] = useState("lefttab-0");
  const { width } = useWindowDimensions();
  const location = useLocation();
  const history = useHistory();

  const tabListBottom = tabBottomData.map((tab, i) => (
    <NavLink
      className="tab_name"
      activeClassName="active"
      exact
      to={tab.link}
      key={`tab-${i}`}
    >
      {tab.title}
    </NavLink>
  ));

  const tabListTop = tabTopData.map((tab, i) => (
    <NavLink
      className="tab_name"
      activeClassName="active"
      exact
      to={tab.link}
      key={`tab-${i}`}
    >
      {tab.title}
    </NavLink>
  ));

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const targetId = hash.substring(1);
      const targetSection = document.getElementById(targetId);
      if (targetSection) {
        setTimeout(() => {
          const offset = 100; // Gap from viewport top
          const targetPosition =
            targetSection.getBoundingClientRect().top + window.scrollY - offset;
          window.scrollTo({ top: targetPosition, behavior: "smooth" });
          // targetSection.scrollIntoView({ behavior: "smooth" });
        }, 0);
        // history.replace("/investor-relations/investor-meet-presentation");
        history.replace(location.pathname);
      }
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink={window.location.href}
        metaTitle="Explore Quarterly & Monthly Financial Performance | Sanathan Textiles"
        metaDesc="Stay updated with Sanathan Textiles' quarterly and monthly financial performance. Explore detailed reports and insights for informed investment decisions."
      />

      <section className="fin_perf_sec1 investor_relations">
        <div className="wrapper">
          <div className="breadcrumb_styles">
            <Link to="/" className="breadcrumb_link">
              Home
            </Link>{" "}
            {">"} Investor Relations
          </div>
          <div className="left_section">
            <h1 className="hollow_title">Investor Relations</h1>
            <div className="dash_wrapper">
              <Dash />
            </div>
            {/* <h3></h3> */}
            {/* <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet.
            </p> */}
          </div>
          <div className="right_section">
            <figure className="bg_img">
              <img src={InvestorBgImg} alt="sanathan img" />
            </figure>
            <figure className="fg_img">
              <img src={InvestorFgImg} alt="sanathan img" />
            </figure>
            <div className="box_stock_hold">{width >= 769 && <Stock />}</div>
          </div>
          {/* mobile section for image*/}
          <div className="mobile_section">
            <figure className="mob_bg_img">
              <img
                className="mob_img1"
                src={InvestorBgImg}
                alt="sanathan img"
              />
            </figure>
            <figure className="mob_fg_img">
              <img src={InvestorFgImg} alt="sanathan img" />
            </figure>
          </div>
        </div>
      </section>
      <section className="stockWrapper stck_ivstor_rel">
        <div className="wrapper">{width <= 768 && <Stock />}</div>
      </section>
      <section className="fin_perf_sec2" id="financial">
        <div className="wrapper">
          <div className="row invest_row">
            <div className="col-md-4 invest_col">
              {width > 767 ? (
                <InvestTab />
              ) : (
                <div className="invest_tab_wrapper">{tabListTop}</div>
              )}
            </div>
            <div className="col-md-8 invest_col">
              <h2 className="heading">Financial Performance & Presentation</h2>
              <div className="row report_row">
                <div className="col-md-6 col-6 report_col report_tab_col">
                  <div className="report_tab_wrapper">
                    <h3
                      className={`report_tab_name ${
                        isActivTab === "quarterly" ? "active" : ""
                      }`}
                      onClick={() => setIsActivTab("quarterly")}
                    >
                      Quarterly
                    </h3>
                  </div>
                </div>
                <div className="col-md-6 col-6 report_col report_tab_col">
                  <div className="report_tab_wrapper">
                    <h3
                      className={`report_tab_name ${
                        isActivTab === "annual" ? "active" : ""
                      }`}
                      onClick={() => setIsActivTab("annual")}
                    >
                      Annual
                    </h3>
                  </div>
                </div>
              </div>
              {isActivTab === "quarterly" && (
                <div className="row report_row">
                  <div className="col-md-6 report_col">
                    <h3 className="year">2024 - 2025</h3>
                    <FinanceAcc
                      accData={financialPerformance2425}
                      dwnIcon
                      activeKey={activeKey}
                      setActiveKey={setActiveKey}
                      columnKey="left"
                    />
                  </div>
                  {/* <div className="col-md-6 report_col">
                    <h3 className="year">2023 - 2024</h3>
                    <FinanceAcc
                      accData={financialPerformance2324}
                      dwnIcon
                      activeKey={activeKey}
                      setActiveKey={setActiveKey}
                      columnKey="right"
                    />
                  </div>
                  <div className="col-md-6 report_col">
                    <h3 className="year">2022-2023</h3>
                    <FinanceAcc
                      accData={financialPerformance2223}
                      dwnIcon
                      activeKey={activeKey}
                      setActiveKey={setActiveKey}
                      columnKey="left01"
                    />
                  </div>
                  <div className="col-md-6 report_col">
                    <h3 className="year">2021-2022</h3>
                    <FinanceAcc
                      accData={financialPerformance2122}
                      dwnIcon
                      activeKey={activeKey}
                      setActiveKey={setActiveKey}
                      columnKey="right01"
                    />
                  </div> */}
                </div>
              )}
              {isActivTab === "annual" && (
                // <div className="row report_row">
                //   <div className="col-md-6 report_col">
                //     <h3 className="year">2024 - 2025</h3>
                //     <FinanceAcc
                //       accData={financialPerformanceTab2425}
                //       dwnIcon
                //       activeKey={activeKeyTab2}
                //       setActiveKey={setActiveKeyTab2}
                //       columnKey="lefttab"
                //     />
                //   </div>
                //   <div className="col-md-6 report_col">
                //     <h3 className="year">2023 - 2024</h3>
                //     <FinanceAcc
                //       accData={financialPerformanceTab2324}
                //       dwnIcon
                //       activeKey={activeKeyTab2}
                //       setActiveKey={setActiveKeyTab2}
                //       columnKey="righttab"
                //     />
                //   </div>
                // </div>
                <InvestAcc
                  accData={financialPerformanceAnual}
                  dwnIcon={width > 767 ? false : true}
                />
              )}
              {width <= 767 && (
                <div className="invest_tab_wrapper invest_tab_wrapper_2">
                  {tabListBottom}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FinancialPerformance;
