export const blog9innerURL =
  "/blogs/future-of-polyester-yarn-in-textile-and-clothing";
export const blog10innerURL =
  "/blogs/cationic-dyeable-polyester-yarn-a-revolution-in-fancy-innovative-fabrics";
export const blog11innerURL =
  "/blogs/leveraging-technology-at-sanathan-textiles";
export const blog12innerURL =
  "/blogs/s-flex-yarn-a-sanathan-textile-stretch-yarn-product";
export const blog13innerURL =
  "/blogs/sanathan-textiles-integrated-manufacturing-facility";
export const blog14innerURL =
  "/blogs/celebrating-innovation-and-transforming-indias-textile-industry";
