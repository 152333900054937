import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  facebook,
  gallery1,
  moreblog1,
  moreblog2,
  moreblog3,
  playicon,
  whatsapp,
  twitterblog,
  copy,
  blogimg12,
} from "../../images";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { Link } from "react-router-dom";
//utils
import { reduceDescSize } from "../../helpers/utils";

import "./BlogInner12.scss";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const BlogInner12 = () => {
  const { width } = useWindowDimensions();
  const [copied, setCopied] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [vidLink, setVidLink] = useState("");

  const blogDateArr = [
    {
      date: "June 2, 2022",
      category: "Lorem Ipsum",
      company: "Sanathan",
    },
  ];

  const blogDescArr = [
    {
      id: 1,
      heading: "S-flex Yarn  -  A Sanathan Textile Stretch Yarn Product.",
      desc1: `
      The textile industry is no stranger to innovation, but every once in a while, a product emerges that redefines the landscape. Enter S-Flex, a self-stretch polyester filament yarn that is setting a new standard for stretch yarns. What makes <a href="/products/s-flex">Spandex-free S-Flex stretch</a> truly groundbreaking is its ability to deliver four-way stretch capabilities without relying on spandex. This innovation promises to revolutionize how we think about comfort, durability, and sustainability in fabrics.
      <br/><br/>
      <strong>Spandex-Free Stretch: A Game Changer</strong>
      <br/><br/>
      Long since when Spandex has become known as the material used for stretch fabrics, these elasticity features cannot be overlooked with that disadvantage such as fabric degradation, weight and involvement in lives. However, S-Flex has removed all the drawbacks and ended with offering a spandex-free four-way stretch.
      <br/><br/>
      This change provides you with a potential imitation for the length and comfort benefit of regular spandex but in a fabric that's lighter, more breathable, and more organic feeling. Plus, without the breakdown of spandex over time, S-Flex preserves elasticity and shape in the fabrics, bestowing unsurpassed durability and lifespan.
      <br/><br/>
      <strong>Enhanced Fabric Properties: More Than Meets the Eye</strong>
      <br/><br/>
      True to its name, S-Flex is the surest stretch-guaranteed fabric. It offers much more in benefits to fabric production, and here are some of the major highlights that its technology brings to the table:
      <br/><br/>
      <strong>Cotton-like Appearance:</strong> Although S-Flex is composed of 100% <a href="/products/polyester-yarn">polyester filament yarns</a>, it provides a natural, cotton-like finish for modern and premium casualwear, enabling the design of perfect innovative wear.
      <br/><br/>
      <strong>Wrinkle Resistance:</strong> No more long ironing sessions. Fabrics made from S-Flex avoid creasing much and, therefore, look fresh and polished taken out from the wardrobe.
      <br/><br/>
      <strong>Low GSM with High Volume:</strong> With S-Flex, the designing of lightweight fabrics can be done without sacrificing density or volume; less material, less cost-effective.
      <br/><br/>
      <strong>Less Destruction:</strong> The yarn resists wear and tear, thus leaving garments look-like new for long.
      <br/><br/>
      <strong>Cost Effective and Easy to Process</strong>
      <br/><br/>
      S-Flex is not only a boon to users; but it is also a dream come true for manufacturers. Traditional spandex-containing fabrics require a great deal of complicated processes along with high production costs. On the other hand, S-Flex provides a less handling-long process and fewer steps. That's all, reduced cost of manufacturing with a better profit margin.
      <br/><br/>
      <strong>Versatile Applications Across Fabric Types</strong>
      <br/><br/>
      Importantly, flexibility tops the list of S-flex's advantages; it's totally compatible with different types of fabric constructions-from knitted, woven to warp knitted fabrics. From sportswear to casuals, this flexibility allows the designer and manufacturer to explore new possibilities. It can be used for high-performance activewear or stylish everyday wear.
      <br/><br/>
      S-Flex certainly has one of its best benefits, which is flexibility. It goes well with just about any kind of fabric constructions-from knitted, woven to warp-knitted fabrics. This fit lets the designer and manufacturer consider new possibilities starting from sportswear down to casual clothing. S-Flex goes for making high-performance activewear, as well as fashionable casual wear.
      <br/><br/>
      <strong>Dimensional Stability: Stretch Without Compromise</strong>
      <br/><br/>
      One of the commonest complaints with stretch fabrics is that they often stretch out or lose their form after prolonged use. S-Flex takes those worries away, thanks to its remarkable dimensional stability. This means the garments keep their shape and fit even after being washed repeatedly. Stretch, sagging, or elasticity loss are a thing of the past. With S-Flex, consistency is guaranteed for providing a first-rate experience for end-users.
      <br/><br/>
      <strong>The Future of Stretch Fabrics</strong>
      <br/><br/>
      It's not just a product. S-Flex is the whole paradigm of change in textile manufacturing. This technology combines the built-in benefits of polyester with previously unattainable stretch performance. Therefore, it is the bridge between comfort, performance, and sustainability. The whole innovative design now enables industry designers and manufacturers to start thinking differently about what fabric can do. 
      <br/><br/>
      Removing spandex is not only a simplification in production but also helps in increasing the longevity and performance of the fabrics, positioning S-Flex as the most economical and eco-friendly option introducing spandex to the textile industry. S-Flex is thus going to be the first choice in this progressive direction of the fashion and <a href="/">textile industry</a>.
      <br/><br/>
      S-Flex is just the right answer when talking about stretching materials; it has the best comfort and long-lasting durability and adaptability. So whether you are in design thinking or manufacturing mood, S-Flex is your step into the future of textiles.
      `,
    },
  ];

  const moreblogsArr = [
    {
      img: moreblog1,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
    {
      img: moreblog2,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
    {
      img: moreblog3,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
    {
      img: moreblog2,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
  ];

  const galleryArr = [
    {
      id: 1,
      type: "video",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
    {
      id: 2,
      type: "image",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
    {
      id: 3,
      type: "video",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
    {
      id: 4,
      type: "image",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
  ];

  useEffect(() => {
    var gallery_swiper_container = new Swiper(
      ".swiper-container.gallery_swiper_container",
      {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: {
          prevEl: ".bloginner_sec2 .left_arrow",
          nextEl: ".bloginner_sec2 .right_arrow",
        },
        breakpoints: {
          767: {
            slidesPerView: 1.6,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: 2.7,
            spaceBetween: 10,
          },
        },
        // loop: true,
        // centeredSlides: true,
        // loopedSlides: thumbArr.length, //how many slides it should duplicate
      }
    );
    var moreblogs_swiper_container = new Swiper(
      ".swiper-container.moreblogs_swiper_container",
      {
        slidesPerView: 3,
        spaceBetween: 40,
        loop: false,
        navigation: {
          prevEl: ".bloginner_sec3 .left_arrow",
          nextEl: ".bloginner_sec3 .right_arrow",
        },
        breakpoints: {
          300: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          767: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        },
      }
    );
  });

  useEffect(() => {
    if (copied) {
      let copiedTimeout = setTimeout(() => {
        setCopied(false);
      }, 3000);
      return () => {
        clearTimeout(copiedTimeout);
      };
    }
  }, [copied]);

  const dateList = blogDateArr.map((item) => (
    <>
      <p className="blog_date">{item.date} | </p>
      <p className="blog_category"> {item.category} </p>
      <p className="blog_company">| {item.company}</p>
    </>
  ));

  const descList = blogDescArr.map((item) => (
    <>
      <p
        className="blog_desc"
        dangerouslySetInnerHTML={{ __html: item.desc1 }}
      />
    </>
  ));

  const galleryVideoImageList = galleryArr.length
    ? galleryArr.map((item, i) => (
        <div className="swiper-slide" key={i}>
          {item.type == "video" ? (
            <>
              <div
                className="video_wrapper_div"
                onClick={() => handleImageWrapperClick(item.ytLink)}
              >
                <div className="content">
                  <img
                    src={item.image}
                    alt="Sanathan video gallery img"
                    loading="lazy"
                    className="gallery_img"
                  />
                  <div className="content-overlay"></div>
                  <img
                    src={playicon}
                    alt="Sanathan coloredArrow img"
                    loading="lazy"
                    className="playicon"
                    onClick={() => handleImageWrapperClick(item.ytLink)}
                  />
                  {/* <h2 className="galleryImageName">{item.title}</h2> */}
                </div>

                {/* <h2 className="galleryImageName">{item.title}</h2> */}
              </div>
            </>
          ) : (
            <>
              <div className="content">
                <img
                  src={item.image}
                  alt="Sanathan blog gallery img"
                  loading="lazy"
                  className="gallery_img"
                />
                <div className="content-overlay"></div>
                {/* <h2 className="galleryImageName">{item.title}</h2> */}
              </div>
            </>
          )}
        </div>
      ))
    : null;

  const moreblogsList = moreblogsArr.map((item, i) => (
    <>
      <li className="swiper-slide moreblog_detail_wrapper">
        <Link to="/blog-inner" key={i}>
          <div className="imageanimate zoom-out">
            <img
              src={item.img}
              alt="Sanathan our blog img"
              loading="lazy"
              className="moreblog_img"
            />
            {/* <div className="content-overlay"></div> */}
          </div>
          <div className="text_container">
            <h1 className="moreblog_date">{item.date}</h1>
            <div className="verticle_line"> | </div>
            <h1 className="moreblog_category"> {item.category}</h1>
            <h1 className="moreblog_name">{item.title}</h1>
            <p className="moreblog_synopsis">
              {reduceDescSize(item.desc, 100)}
            </p>
            <div className="moreblog_cta_wrapper"></div>
          </div>
        </Link>
      </li>
    </>
  ));

  const handleImageWrapperClick = (videoLink) => {
    setToggler(!toggler);
    setVidLink(videoLink);
  };

  const copyUrl = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink={window.location.href}
        metaTitle="S-flex Yarn - A Sanathan Textile Stretch Yarn Product"
        metaDesc="Discover how Sanathan Textiles uses automation and data-driven decisions to boost efficiency, reduce costs, and lead the textile industry in innovation."
      />
      <section className="bloginner_sec1" data-aos="fade-in">
        <div className="wrapper">
          <img
            className="mainimg"
            src={blogimg12}
            alt="Sanathan blog inner img"
          />
          <div className="row social_icon_date">
            <div className="col-md-6 col-lg-4 datecat_list">
              {/* {dateList} */}
            </div>
            <div className="col-md-1 col-lg-4"></div>
            <div className="col-md-5 col-lg-4">
              {width < 767 ? <h2 className="share_title">Share</h2> : null}
              <div className="social_main_wrapper">
                <ul className="social_icons">
                  {width > 767 ? (
                    <li className="social_icon_li">
                      <h2 className="share_title">Share</h2>
                    </li>
                  ) : null}
                  <li className="social_icon_li">
                    <FacebookShareButton
                      url={`${
                        window.location.href ? window.location.href : null
                      }`}
                    >
                      <span className="social_icon">
                        <img
                          src={facebook}
                          alt="Sanathan facebook logo"
                          loading="lazy"
                        />
                      </span>
                    </FacebookShareButton>
                  </li>
                  <li className="social_icon_li">
                    <WhatsappShareButton
                      url={`${
                        window.location.href ? window.location.href : null
                      }`}
                    >
                      <span className="social_icon">
                        <img
                          src={whatsapp}
                          alt="Sanathan facebook logo"
                          loading="lazy"
                        />
                      </span>
                    </WhatsappShareButton>
                  </li>
                  <li className="social_icon_li">
                    <TwitterShareButton
                      url={`${
                        window.location.href ? window.location.href : null
                      }`}
                    >
                      <span className="social_icon">
                        <img
                          src={twitterblog}
                          alt="Sanathan facebook logo"
                          loading="lazy"
                        />
                      </span>
                    </TwitterShareButton>
                  </li>
                  <li
                    className="social_icon_li social_icon_li_copy"
                    onClick={copyUrl}
                  >
                    <span className="social_icon">
                      <img
                        src={copy}
                        alt="Sanathan copy url logo"
                        loading="lazy"
                      />
                    </span>
                    <span className="social_name">
                      {!copied ? "" : "Copied!"}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="horizontal_line"></div>
          <h1 className="blog_inner_name">{blogDescArr[0].heading}</h1>
          <div>{descList}</div>
        </div>
      </section>
    </>
  );
};

export default BlogInner12;
