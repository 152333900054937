import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  facebook,
  gallery1,
  moreblog1,
  moreblog2,
  moreblog3,
  playicon,
  whatsapp,
  twitterblog,
  copy,
  blogimg13,
} from "../../images";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { Link } from "react-router-dom";
//utils
import { reduceDescSize } from "../../helpers/utils";

import "./BlogInner13.scss";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const BlogInner13 = () => {
  const { width } = useWindowDimensions();
  const [copied, setCopied] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [vidLink, setVidLink] = useState("");

  const blogDateArr = [
    {
      date: "June 2, 2022",
      category: "Lorem Ipsum",
      company: "Sanathan",
    },
  ];

  const blogDescArr = [
    {
      id: 1,
      heading: "Sanathan Textiles Integrated Manufacturing facility with 220,000+ MTPA Capacity Offers Diverse Yarn Products and High-Value Innovation.",
      desc1: `
      At <a href="/">Sanathan Textiles</a>' Silvassa facility, we are committed to delivering high-quality yarn products through a meticulous and transparent production process. To uphold this vision, we are committed to maintaining a meticulous and transparent production process. Our integrated manufacturing setup in Silvassa boasts a capacity exceeding 220,000 MTPA, while our facility in Punjab is designed with a capacity of 3.50,000 MTPA at peak capacity, enabling the production of cotton yarn, polyester yarn, and yarns for technical textile.
      <br/><br/>
      To ensure our Polyester yarn possesses consistent quality, we have installed a Continuous Polymerization Plant (CP) that features a 100% integrated downstream process including spinning, texturing, twisting, and air texturizing ensuring high value addition with backward forward integration of process. This innovation helps reduce gas emissions, water usage, minimizes energy consumption, material movement cost overheads and waste generation  all while maintaining the highest product quality.
      <br/><br/>
      <strong>Our Products:</strong>
      <br/><br/>
      Our diverse product range includes three main yarn verticals, each designed to meet the varying needs of our customers. A well designed and integrated manufacturing setup is keenly focused on offering customers made to order(MTO) yarns, niche yarns, coloured yarns and other value added yarns across all three yarn divisions.
      <br/><br/>
      <strong>Cotton Yarns:</strong>
      <br/><br/>
      Our line of <a href="/products/cotton-yarn">pure cotton yarns</a>, which we sell under the Brand “ Puro Cotton Yarns” are used to create fabrics for clothings, suits, shirts, bed linens, home textiles, and other final applications.
      <br/><br/>
      <strong>Polyester Yarns:</strong>
      <br/><br/>
      Our polyester filament yarn division is a fully integrated setup, utilizing petrochemicals to produce different types of polyester yarns. The polymerization process, with three lusters, provides the flexibility to meet all customer needs. We are particularly focused on producing born-dyed colour yarns, functional yarns such as <a href="/products/s-flex">S-Flex</a>, Stretch, Drycool, and made-to-order (customized) yarns, and other value-added products.
      <br/><br/>
      <strong>Technical Textile yarn: </strong>
      <br/><br/>
      <a href="/products/yarn-for-technical-textile">Technical textiles</a> have been steadily gaining traction due to their functional benefits, including  health & safety, cost effectiveness, durability, high strength, light weight properties, versatility, customization, user friendliness, eco friendliness and logistical convenience.
      <br/><br/>
      <strong>Sanathan Reviro Yarns:</strong>
      <br/><br/>
      Sustainbility and recyclability are at the core of <a href="/products/reviro">Sanathan Reviro</a>. Our resolve is to contribute to the new world’s efforts to reduce carbon footprints by using fewer natural resources. In future we aim at using textile waste resourcefully to complete the loop in the textile value chain and practice conscious consumption.
      <br/><br/>
      <strong>Born Dyed Yarns:</strong>
      <br/><br/>
      Our range of <a href="/products/born-dyed">polyester colored yarns</a>—‘BornDyed’—are made using dope-dyeing technology, where color pigment is added to the yarns at the polymer stage. Born Dyed Yarns significantly reduce water pollution. The process eliminates the need for conventional dyeing, allowing yarns to directly go for knitting and weaving of fabrics. These yarns offer excellent quality, color fastness, and are eco-friendly. Available in a wide range of colors and made to order (MTO), BornDyed Yarns present an efficient and sustainable solution for various textile applications.
      <br/><br/>
      <strong>Sanathan S-Flex Yarns:</strong>
      <br/><br/>
      S-flex is a groundbreaking self-stretch polyester filament yarn. It offers four-way stretch capabilities without spandex. This means you can enjoy the same stretch and comfort as traditional stretch fabrics at an even lighter weight , with a more natural and breathable feel. This innovative yarn promises cost-effective, easy-to-handle fabric production with superior performance across knitted, woven, and warp knitted fabrics.
      <br/><br/>
      <strong>Sanathan STRETCH Yarns:</strong>
      <br/><br/>
      Stretch Yarns are innovative yarns designed to offer exceptional elasticity and comfort. By incorporating elastic fibers such as spandex, elastane, or Lycra, these yarns allow fabrics to stretch and return to their original shape. This property makes them ideal for activewear, swimwear, hosiery, and everyday apparel, providing superior comfort and flexibility.
      <br/><br/>
      <strong>Sanathan CDP Yarns:</strong>
      <br/><br/>
      Sanathan CDP (Cationic Dyeable Polyester) is a specialised polyster yarn that can be coloured with cationic dyes. It shows excellent colour development and fastness and enhances dyeability and result in more vibrant and long lasting colours.
      <br/><br/>
      <strong>Sanathan Drycool Yarns:</strong>
      <br/><br/>
      With the moisture-management features, Sanathan <a href="/products/dry-cool">Drycool yarn</a> is well suited for the making of fabrics that help in keeping the wearer dry and comfortable. The lighter weight also contributes in bringing all kinds of textiles for active wear and functional garments better suited to the comfort of the wearer in varied environments.
      <br/><br/>
      <strong>Our Silvassa Unit:</strong>
      <br/><br/>
       Our manufacturing base in Silvassa plays a vital role in complementing the production of necessary yarns for these successful outcomes. State-of-the-art technology has enabled us to develop a robust and efficient production system, paired with an unwavering commitment to quality. This facility not only supplements large-scale manufacturing capacity but also serves as a platform for innovation, quality control, and sustainability. The Silvassa unit stands as a testament to our ability to meet the ever-growing demands of the textile sector while maintaining a strong commitment to environmental responsibility.
      `,
    },
  ];

  const moreblogsArr = [
    {
      img: moreblog1,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
    {
      img: moreblog2,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
    {
      img: moreblog3,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
    {
      img: moreblog2,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
  ];

  const galleryArr = [
    {
      id: 1,
      type: "video",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
    {
      id: 2,
      type: "image",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
    {
      id: 3,
      type: "video",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
    {
      id: 4,
      type: "image",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
  ];

  useEffect(() => {
    var gallery_swiper_container = new Swiper(
      ".swiper-container.gallery_swiper_container",
      {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: {
          prevEl: ".bloginner_sec2 .left_arrow",
          nextEl: ".bloginner_sec2 .right_arrow",
        },
        breakpoints: {
          767: {
            slidesPerView: 1.6,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: 2.7,
            spaceBetween: 10,
          },
        },
        // loop: true,
        // centeredSlides: true,
        // loopedSlides: thumbArr.length, //how many slides it should duplicate
      }
    );
    var moreblogs_swiper_container = new Swiper(
      ".swiper-container.moreblogs_swiper_container",
      {
        slidesPerView: 3,
        spaceBetween: 40,
        loop: false,
        navigation: {
          prevEl: ".bloginner_sec3 .left_arrow",
          nextEl: ".bloginner_sec3 .right_arrow",
        },
        breakpoints: {
          300: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          767: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        },
      }
    );
  });

  useEffect(() => {
    if (copied) {
      let copiedTimeout = setTimeout(() => {
        setCopied(false);
      }, 3000);
      return () => {
        clearTimeout(copiedTimeout);
      };
    }
  }, [copied]);

  const dateList = blogDateArr.map((item) => (
    <>
      <p className="blog_date">{item.date} | </p>
      <p className="blog_category"> {item.category} </p>
      <p className="blog_company">| {item.company}</p>
    </>
  ));

  const descList = blogDescArr.map((item) => (
    <>
      <p
        className="blog_desc"
        dangerouslySetInnerHTML={{ __html: item.desc1 }}
      />
    </>
  ));

  const galleryVideoImageList = galleryArr.length
    ? galleryArr.map((item, i) => (
        <div className="swiper-slide" key={i}>
          {item.type == "video" ? (
            <>
              <div
                className="video_wrapper_div"
                onClick={() => handleImageWrapperClick(item.ytLink)}
              >
                <div className="content">
                  <img
                    src={item.image}
                    alt="Sanathan video gallery img"
                    loading="lazy"
                    className="gallery_img"
                  />
                  <div className="content-overlay"></div>
                  <img
                    src={playicon}
                    alt="Sanathan coloredArrow img"
                    loading="lazy"
                    className="playicon"
                    onClick={() => handleImageWrapperClick(item.ytLink)}
                  />
                  {/* <h2 className="galleryImageName">{item.title}</h2> */}
                </div>

                {/* <h2 className="galleryImageName">{item.title}</h2> */}
              </div>
            </>
          ) : (
            <>
              <div className="content">
                <img
                  src={item.image}
                  alt="Sanathan blog gallery img"
                  loading="lazy"
                  className="gallery_img"
                />
                <div className="content-overlay"></div>
                {/* <h2 className="galleryImageName">{item.title}</h2> */}
              </div>
            </>
          )}
        </div>
      ))
    : null;

  const moreblogsList = moreblogsArr.map((item, i) => (
    <>
      <li className="swiper-slide moreblog_detail_wrapper">
        <Link to="/blog-inner" key={i}>
          <div className="imageanimate zoom-out">
            <img
              src={item.img}
              alt="Sanathan our blog img"
              loading="lazy"
              className="moreblog_img"
            />
            {/* <div className="content-overlay"></div> */}
          </div>
          <div className="text_container">
            <h1 className="moreblog_date">{item.date}</h1>
            <div className="verticle_line"> | </div>
            <h1 className="moreblog_category"> {item.category}</h1>
            <h1 className="moreblog_name">{item.title}</h1>
            <p className="moreblog_synopsis">
              {reduceDescSize(item.desc, 100)}
            </p>
            <div className="moreblog_cta_wrapper"></div>
          </div>
        </Link>
      </li>
    </>
  ));

  const handleImageWrapperClick = (videoLink) => {
    setToggler(!toggler);
    setVidLink(videoLink);
  };

  const copyUrl = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink={window.location.href}
        metaTitle="Sanathan Textiles – 220,000+ MTPA Yarn Production - High-Quality & Innovative Yarns"
        metaDesc="Discover Sanathan Textiles’ integrated manufacturing facility with 220,000+ MTPA capacity, offering premium cotton, polyester & technical textile yarns with innovation"
      />
      <section className="bloginner_sec1" data-aos="fade-in">
        <div className="wrapper">
          <img
            className="mainimg"
            src={blogimg13}
            alt="Sanathan blog inner img"
          />
          <div className="row social_icon_date">
            <div className="col-md-6 col-lg-4 datecat_list">
              {/* {dateList} */}
            </div>
            <div className="col-md-1 col-lg-4"></div>
            <div className="col-md-5 col-lg-4">
              {width < 767 ? <h2 className="share_title">Share</h2> : null}
              <div className="social_main_wrapper">
                <ul className="social_icons">
                  {width > 767 ? (
                    <li className="social_icon_li">
                      <h2 className="share_title">Share</h2>
                    </li>
                  ) : null}
                  <li className="social_icon_li">
                    <FacebookShareButton
                      url={`${
                        window.location.href ? window.location.href : null
                      }`}
                    >
                      <span className="social_icon">
                        <img
                          src={facebook}
                          alt="Sanathan facebook logo"
                          loading="lazy"
                        />
                      </span>
                    </FacebookShareButton>
                  </li>
                  <li className="social_icon_li">
                    <WhatsappShareButton
                      url={`${
                        window.location.href ? window.location.href : null
                      }`}
                    >
                      <span className="social_icon">
                        <img
                          src={whatsapp}
                          alt="Sanathan facebook logo"
                          loading="lazy"
                        />
                      </span>
                    </WhatsappShareButton>
                  </li>
                  <li className="social_icon_li">
                    <TwitterShareButton
                      url={`${
                        window.location.href ? window.location.href : null
                      }`}
                    >
                      <span className="social_icon">
                        <img
                          src={twitterblog}
                          alt="Sanathan facebook logo"
                          loading="lazy"
                        />
                      </span>
                    </TwitterShareButton>
                  </li>
                  <li
                    className="social_icon_li social_icon_li_copy"
                    onClick={copyUrl}
                  >
                    <span className="social_icon">
                      <img
                        src={copy}
                        alt="Sanathan copy url logo"
                        loading="lazy"
                      />
                    </span>
                    <span className="social_name">
                      {!copied ? "" : "Copied!"}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="horizontal_line"></div>
          <h1 className="blog_inner_name">{blogDescArr[0].heading}</h1>
          <div>{descList}</div>
        </div>
      </section>
    </>
  );
};

export default BlogInner13;
