import React from "react";
import "./financeAcc.scss";
import Accordion from "react-bootstrap/Accordion";
import {
  investDwnIcon,
  investPdfIcon,
  investUpArrow,
  mp3Icon,
} from "../../images";

const FinanceAcc = ({
  accData,
  dwnIcon,
  activeKey,
  setActiveKey,
  columnKey,
  audio,
}) => {
  const handleAccordionClick = (key) => {
    const newKey =
      activeKey === `${columnKey}-${key}` ? null : `${columnKey}-${key}`;
    setActiveKey(newKey);
  };

  return (
    <div className="fin_acc_container">
      <Accordion defaultActiveKey="0" activeKey={activeKey} className="fin_acc">
        {accData.map((item, i) => (
          <Accordion.Item
            // eventKey={i + ""}
            eventKey={`${columnKey}-${i}`}
            key={i}
          >
            <Accordion.Header onClick={() => handleAccordionClick(i)}>
              <span>{item.mainTitle}</span>
              <img src={investUpArrow} alt="arrow" className="arrow" />
            </Accordion.Header>

            <Accordion.Body>
              {item?.data?.map((data, index) => (
                <div className="pdf_container" key={index}>
                  <div className={`pdf_text_wrapper`}>
                    <img
                      src={data.type === "audio" ? mp3Icon : investPdfIcon}
                      alt="pdf icon"
                      className="pdf_icon"
                    />
                    <h4 className="pdf_title">{data.title}</h4>
                  </div>
                  {dwnIcon ? (
                    <a
                      href={data.link}
                      target={`${data.type === "external" ? "_blank" : ""}`}
                      download={data.type === "external" ? false : true}
                      className={`pdf_link`}
                    >
                      <img
                        src={investDwnIcon}
                        alt="download"
                        className="dwn_icon"
                      />
                    </a>
                  ) : (
                    <a
                      href={data.link}
                      target={`${data.type === "external" ? "_blank" : ""}`}
                      download={data.type === "external" ? false : true}
                      className="pdf_link"
                    >
                      Download
                    </a>
                  )}
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default FinanceAcc;
