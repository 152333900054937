import React, { useState, useEffect } from "react";
import "./stock.scss";
import { graphStock, stockDown, stockUp } from "../../images";
import axios from "axios";
import moment from "moment"
const Stock = () => {
  const [stock, setStock] = useState({
    pricepercentchange:"0.63",
    pricecurrent:"313",
    pricechange:"2.15"
  });

  const fetchStock = async () => {
    try {
      const data = await axios.get(
        "https://priceapi.moneycontrol.com/pricefeed/nse/equitycash/STL09"
      );
      if (data?.data) {
        setStock(data?.data?.data);
      }
      console.log(data);
    } catch (error) {
      console.log("stock api error", error);
    }
  };

  useEffect(() => {
    fetchStock();
  }, []);

  return (
    <div className="stock_box">
      <div className="stock_light_heading">NSE</div>
      <div className="stock_md_heading">Sanathan Textiles</div>
      <div className="stock_rate_graph_wrapper">
        <h1 className="stock_rate">₹{stock?.pricecurrent}</h1>
        <img src={graphStock} alt="graph" className="stock_graph_img" />
      </div>
      {Math.sign(Number(stock.pricepercentchange)) > 0 ? (
        <div className="stock_up_down_percentage growth_stock">
          <img src={stockUp} alt="stock-Down-icon" className="stock_d_icon" />
          <span> {Number(stock?.pricechange).toFixed(2)} ({Number(stock?.pricepercentchange).toFixed(2)}%)</span>
        </div>
      ) : (
        <div className="stock_up_down_percentage">
          <img src={stockDown} alt="stock-Down-icon" className="stock_d_icon" />
          <span> {Number(stock?.pricechange).toFixed(2)} ({Number(stock?.pricepercentchange).toFixed(2)}%)</span>
        </div>
      )}

      <div className="date_stock_wrapper">
        <div className="date_stock">{moment(stock?.lastupd).format("Do MMM, YYYY")}</div>
        <div className="time_stock">{moment(stock?.lastupd).format("h:mm A")}</div>
      </div>
    </div>
  );
};

export default Stock;
