import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  facebook,
  gallery1,
  moreblog1,
  moreblog2,
  moreblog3,
  playicon,
  whatsapp,
  twitterblog,
  copy,
  blogimg11,
} from "../../images";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { Link } from "react-router-dom";
//utils
import { reduceDescSize } from "../../helpers/utils";

import "./BlogInner11.scss";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const BlogInner11 = () => {
  const { width } = useWindowDimensions();
  const [copied, setCopied] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [vidLink, setVidLink] = useState("");

  const blogDateArr = [
    {
      date: "June 2, 2022",
      category: "Lorem Ipsum",
      company: "Sanathan",
    },
  ];

  const blogDescArr = [
    {
      id: 1,
      heading:
        "Leveraging Technology at Sanathan Textiles: Automation, Efficiency, and Data-Driven decision",
      desc1: `
      The global textile industry is evolving rapidly, and businesses need reliable partners who can deliver innovation, precision, and sustainability. Sanathan Textiles emerged as a frontrunner, leveraging cutting-edge technology to redefine manufacturing processes and meet the demands of modern enterprises.
      <br/><br/>
      <strong>Automation: Streamlining Processes for Precision and Speed</strong>
      <br/><br/>
      Automation at <a href="/">Sanathan Textiles</a> isn’t just about replacing manual work—it’s about enhancing productivity while maintaining impeccable quality. By integrating automated manufacturing setup, Sanathan ensures consistent, precise output across every stage of production. We are seamlessly implementing contemporary innovations to bridge the gap toward breakthrough advancement. 
      <br/><br/>
      <ul>
      <li>Automated warehouse management system to ensure high efficiency, less damage and better inventory management in limited space for handling over 1,00,000 SKU.</li>
      <li>Process automation helps with the reduction of labour dependency while technology helps to reduce production costs.</li>
      <li>Data-driven decision-making for higher profitability and timely management of production to service a variety of products.</li>
      </ul>
      <br/><br/>
      <strong>Benefits for Business Partners:</strong>
      <ul>
      <li>Reduced lead times for faster market delivery</li>
      <li>Reliable quality, batch after batch</li>
      <li>Cost-effective solutions for large-scale production</li>
      <li>Efficiency Through Smart Manufacturing Systems</li>
      </ul>
      <br/><br/>
      We have decoded a way to smart manufacturing into textile industries through the coupling of Internet of things-enabled devices, sensors, and real-time monitoring tools. These provide smooth workflows, reduced downtime, and facilitate seamless coordination across production units.
      <br/><br/>
      This ensures reliable time frames for our customers and visibility on the production processes. Predictive maintenance and robust tracking systems mean that operations do not disrupt and channels of communication are improved.
      <br/><br/>
      <strong>Data-Driven Innovation for Market Success</strong>
      <br/><br/>
      Sanathan Textiles uses data analytics to analyze trends, process improvements, and appropriate product offerings for markets. Data-driven insights enable the company to create demanded products such as demand forecasting and inventory optimization and yarn-specific application handling
      <br/><br/>
      For businesses, this ensures customized solutions that resonate with end-users and align with market trends.
      <br/><br/>
      <strong>Sustainability Meets Business Needs</strong>
      <br/><br/>
      Sanathan Textiles has acknowledged the rapidly growing importance of sustainability in the textile supply chain. To assure the company commitment to global environmental standards, it utilizes advanced technologies such as Dope Dyed Technologies for eco-friendly colour yarns and recycled materials.
      <br/><br/>
      Innovative yarns like <a href="/products/s-flex">S-Flex (spandex-free stretch yarn)</a> and <a href="/products/dry-cool">Drycool yarn</a> (moisture-wicking yarn) offers functional advantages while reducing environmental impact. Eco-conscious packaging, such as recycled paper, Solar power generation, further aligns Sanathan with the values of sustainability-focused businesses.
      <br/><br/>
      <strong>Key Value Propositions:</strong>
      <ul>
      <li>Access to advanced yarn technologies (e.g. four-way stretch, moisture management)</li>
      <li>Reliable, scalable production processes.</li>
      <li>Support for sustainability goals through eco-conscious practices.</li>
      </ul>
      <br/><br/>
      <strong>Future-Focused Partnerships</strong>
      <br/><br/>
      Sanathan Textiles commitment to technology-driven innovation positions it as a preferred partner for businesses seeking cutting-edge textile solutions. Whether it’s high-performance yarns for sportswear, durable fibers for industrial use, or functional textiles for everyday applications, Sanathan offers unmatched expertise and reliability.
      <br/><br/>
      By combining automation, efficiency, and sustainability, Sanathan Textiles ensures that its partners are equipped to thrive in an increasingly competitive and eco-conscious marketplace. Partner with Sanathan Textiles to future-proof your textile supply chain.
      <br/><br/>
      Explore our offerings today to take your business to the next level.
      `,
    },
  ];

  const moreblogsArr = [
    {
      img: moreblog1,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
    {
      img: moreblog2,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
    {
      img: moreblog3,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
    {
      img: moreblog2,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
  ];

  const galleryArr = [
    {
      id: 1,
      type: "video",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
    {
      id: 2,
      type: "image",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
    {
      id: 3,
      type: "video",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
    {
      id: 4,
      type: "image",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
  ];

  useEffect(() => {
    var gallery_swiper_container = new Swiper(
      ".swiper-container.gallery_swiper_container",
      {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: {
          prevEl: ".bloginner_sec2 .left_arrow",
          nextEl: ".bloginner_sec2 .right_arrow",
        },
        breakpoints: {
          767: {
            slidesPerView: 1.6,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: 2.7,
            spaceBetween: 10,
          },
        },
        // loop: true,
        // centeredSlides: true,
        // loopedSlides: thumbArr.length, //how many slides it should duplicate
      }
    );
    var moreblogs_swiper_container = new Swiper(
      ".swiper-container.moreblogs_swiper_container",
      {
        slidesPerView: 3,
        spaceBetween: 40,
        loop: false,
        navigation: {
          prevEl: ".bloginner_sec3 .left_arrow",
          nextEl: ".bloginner_sec3 .right_arrow",
        },
        breakpoints: {
          300: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          767: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        },
      }
    );
  });

  useEffect(() => {
    if (copied) {
      let copiedTimeout = setTimeout(() => {
        setCopied(false);
      }, 3000);
      return () => {
        clearTimeout(copiedTimeout);
      };
    }
  }, [copied]);

  const dateList = blogDateArr.map((item) => (
    <>
      <p className="blog_date">{item.date} | </p>
      <p className="blog_category"> {item.category} </p>
      <p className="blog_company">| {item.company}</p>
    </>
  ));

  const descList = blogDescArr.map((item) => (
    <>
      <p
        className="blog_desc"
        dangerouslySetInnerHTML={{ __html: item.desc1 }}
      />
    </>
  ));

  const galleryVideoImageList = galleryArr.length
    ? galleryArr.map((item, i) => (
        <div className="swiper-slide" key={i}>
          {item.type == "video" ? (
            <>
              <div
                className="video_wrapper_div"
                onClick={() => handleImageWrapperClick(item.ytLink)}
              >
                <div className="content">
                  <img
                    src={item.image}
                    alt="Sanathan video gallery img"
                    loading="lazy"
                    className="gallery_img"
                  />
                  <div className="content-overlay"></div>
                  <img
                    src={playicon}
                    alt="Sanathan coloredArrow img"
                    loading="lazy"
                    className="playicon"
                    onClick={() => handleImageWrapperClick(item.ytLink)}
                  />
                  {/* <h2 className="galleryImageName">{item.title}</h2> */}
                </div>

                {/* <h2 className="galleryImageName">{item.title}</h2> */}
              </div>
            </>
          ) : (
            <>
              <div className="content">
                <img
                  src={item.image}
                  alt="Sanathan blog gallery img"
                  loading="lazy"
                  className="gallery_img"
                />
                <div className="content-overlay"></div>
                {/* <h2 className="galleryImageName">{item.title}</h2> */}
              </div>
            </>
          )}
        </div>
      ))
    : null;

  const moreblogsList = moreblogsArr.map((item, i) => (
    <>
      <li className="swiper-slide moreblog_detail_wrapper">
        <Link to="/blog-inner" key={i}>
          <div className="imageanimate zoom-out">
            <img
              src={item.img}
              alt="Sanathan our blog img"
              loading="lazy"
              className="moreblog_img"
            />
            {/* <div className="content-overlay"></div> */}
          </div>
          <div className="text_container">
            <h1 className="moreblog_date">{item.date}</h1>
            <div className="verticle_line"> | </div>
            <h1 className="moreblog_category"> {item.category}</h1>
            <h1 className="moreblog_name">{item.title}</h1>
            <p className="moreblog_synopsis">
              {reduceDescSize(item.desc, 100)}
            </p>
            <div className="moreblog_cta_wrapper"></div>
          </div>
        </Link>
      </li>
    </>
  ));

  const handleImageWrapperClick = (videoLink) => {
    setToggler(!toggler);
    setVidLink(videoLink);
  };

  const copyUrl = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink={window.location.href}
        metaTitle="Leveraging Technology at Sanathan Textiles for Smarter Operations"
        metaDesc="Discover how Sanathan Textiles uses automation and data-driven decisions to boost efficiency, reduce costs, and lead the textile industry in innovation."
      />
      <section className="bloginner_sec1" data-aos="fade-in">
        <div className="wrapper">
          <img
            className="mainimg"
            src={blogimg11}
            alt="Sanathan blog inner img"
          />
          <div className="row social_icon_date">
            <div className="col-md-6 col-lg-4 datecat_list">
              {/* {dateList} */}
            </div>
            <div className="col-md-1 col-lg-4"></div>
            <div className="col-md-5 col-lg-4">
              {width < 767 ? <h2 className="share_title">Share</h2> : null}
              <div className="social_main_wrapper">
                <ul className="social_icons">
                  {width > 767 ? (
                    <li className="social_icon_li">
                      <h2 className="share_title">Share</h2>
                    </li>
                  ) : null}
                  <li className="social_icon_li">
                    <FacebookShareButton
                      url={`${
                        window.location.href ? window.location.href : null
                      }`}
                    >
                      <span className="social_icon">
                        <img
                          src={facebook}
                          alt="Sanathan facebook logo"
                          loading="lazy"
                        />
                      </span>
                    </FacebookShareButton>
                  </li>
                  <li className="social_icon_li">
                    <WhatsappShareButton
                      url={`${
                        window.location.href ? window.location.href : null
                      }`}
                    >
                      <span className="social_icon">
                        <img
                          src={whatsapp}
                          alt="Sanathan facebook logo"
                          loading="lazy"
                        />
                      </span>
                    </WhatsappShareButton>
                  </li>
                  <li className="social_icon_li">
                    <TwitterShareButton
                      url={`${
                        window.location.href ? window.location.href : null
                      }`}
                    >
                      <span className="social_icon">
                        <img
                          src={twitterblog}
                          alt="Sanathan facebook logo"
                          loading="lazy"
                        />
                      </span>
                    </TwitterShareButton>
                  </li>
                  <li
                    className="social_icon_li social_icon_li_copy"
                    onClick={copyUrl}
                  >
                    <span className="social_icon">
                      <img
                        src={copy}
                        alt="Sanathan copy url logo"
                        loading="lazy"
                      />
                    </span>
                    <span className="social_name">
                      {!copied ? "" : "Copied!"}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="horizontal_line"></div>
          <h1 className="blog_inner_name">{blogDescArr[0].heading}</h1>
          <div>{descList}</div>
        </div>
      </section>
    </>
  );
};

export default BlogInner11;
